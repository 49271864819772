<template>
  <OfferTile
    :offer="offer"
    :tag-title="tagTitle"
    class="offer-tile-container"
    link-tag="nuxt-link"
  >
    <template #image>
      <CdnImage
        v-if="localImagePath"
        :alt="title"
        :src="localImagePath"
        class="offer-tile__image"
        itemprop="image"
        render-without-figure
      />
      <ProductTileBadge
        :content="rebateDescription"
        :footnote-content="footnoteText"
        :is-discounted="!!discount"
        :is-new="offer.isNew"
        class="offer-tile__badge"
      />
    </template>
    <template #energyLabel>
      <EnergyLabelContainer
        :product="offer.product"
        class="offer-tile-container__energy-label"
        datasheet-alignment="justify"
      />
    </template>
    <template #alternatives>
      <div
        v-if="isBundle"
        class="offer-tile__variants"
      >
        <AlternativeOffersCount :product-alternatives="offer.productAlternatives" />
      </div>
    </template>
    <template #info>
      <div v-if="inStock">
        <span v-if="deliveryLeadTime">
          Lieferzeit: <span class="text text--nowrap">{{ deliveryLeadTime }}</span>
        </span>
      </div>
      <div
        v-else
        class="offer-tile-container__unavailable"
      >
        {{ availability.text }}
      </div>
    </template>
    <template #contentAfter>
      <div class="offer-tile-container__actions">
        <LinkButton
          class="offer-tile-container__action-details"
          data-tealium-rel="content.button.zu-den-details"
          is-wide
          tag="button"
          v-html="buttonLabel"
        />
        <AddToCartButtonContainer
          :display-cart-quantity="true"
          :display-text="false"
          :offer="offer"
          theme="default"
        />
      </div>
    </template>
  </OfferTile>
</template>

<script>
import { mapGetters } from 'vuex';
import { getDiscount, getPrice } from '@i22/rocket';
import { attachmentThumbUrl } from '@/lib/attachment-route';
import { LinkButton, OfferTile, availabilityCalculator } from '@i22-td-smarthome/component-library';

import AddToCartButtonContainer from '@/components/product-detail/add-to-cart-button-container.vue';
import AlternativeOffersCount from '@/components/alternative-offers-count.vue';
import EnergyLabelContainer from '@/components/energy-label-container.vue';
import ProductTileBadge from '@/components/product-detail/product-tile-badge.vue';
import CdnImage from '@/components/cdn-image.vue';

export default {
  name: 'OfferTileContainer',
  components: {
    AddToCartButtonContainer,
    AlternativeOffersCount,
    CdnImage,
    LinkButton,
    EnergyLabelContainer,
    ProductTileBadge,
    OfferTile,
  },
  props: {
    tagTitle: { type: String, default: 'h2' },
    buttonLabel: {
      type: String,
      default: '<span class="show-from-mid-large">Zu den </span>Details',
    },
    offer: { type: Object, required: true },
  },
  computed: {
    ...mapGetters('offers', ['rebates']),
    availability() {
      return availabilityCalculator(
        this.offer.product?.stock?.amount,
        this.offer.product?.alwaysDisplayed
      );
    },
    title() {
      if (this.offer.contract) return this.offer.contract.name;
      return this.offer.product.family.name;
    },
    isBundle() {
      return !!(this.offer.product && this.offer.product.bundle);
    },
    deliveryLeadTime() {
      if (this.offer.contract) return '';
      return this.offer.product.deliveryLeadTime;
    },
    rebate() {
      return this.rebates[this.offer.product.articleNumber];
    },
    rebateDescription() {
      return this.rebate?.description;
    },
    footnoteText() {
      return this.rebate?.footnote;
    },
    localImagePath() {
      return attachmentThumbUrl(this.offer.teaserImage);
    },
    inStock() {
      const { product } = this.offer;
      // An offer must always contain at least a product or contract (or both).
      // When no product is present in the offer we can be certain to have a
      // contract. As contracts are intangible they may not have stock and are
      // considered always available.
      if (!product) return true;
      return product.stock.amount > 0;
    },
    offerLink() {
      if (this.offer.contract) return `/service/${this.offer.contract.slug}`;
      return `/geraete/${this.offer.product.slug}`;
    },
    price() {
      return getPrice(this.offer.prices, 'DEFAULT', 'ONETIME')?.value;
    },
    wasPrice() {
      return getPrice(this.offer.prices, 'WAS', 'ONETIME')?.value;
    },
    discount() {
      return getDiscount(this.price, this.wasPrice);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/base';
.offer-tile-container {
  --border-radius: 0;
  border: 1px solid #dcdcdc;

  :deep(.offer-tile__link-wrapper) {
    box-shadow: none;
  }

  :deep(.offer-tile__title-wrapper) {
    order: -2;
  }

  :deep(.offer-tile__image-wrapper) {
    order: -1;
    margin-bottom: $grid-base;
  }

  :deep(.offer-tile__brand) {
    display: none;
  }

  :deep(.heading) {
    text-align: center;
  }

  :deep(.offer-tile__variants) {
    position: relative;
  }
  :deep(.offer-tile-color-display) {
    flex-direction: row;
    grid-gap: $grid-base;
    justify-content: center;
  }
  :deep(.offer-tile-color-display__btn) {
    height: 18px;
    width: 18px;
  }
  :deep(.offer-tile__sizes) {
    order: -2;
  }
  :deep(.offer-tile__content) {
    align-items: center;
  }
  :deep(.offer-tile__info) {
    justify-content: center;
  }

  &__actions {
    order: -1;
    width: 100%;
    display: flex;
    grid-gap: $grid-base;
  }

  &__action-details {
    flex: 1 1 100%;
  }
}
</style>
