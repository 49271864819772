<template>
  <div
    v-if="hasBadge"
    class="product-tile-badge"
  >
    <Badge
      v-if="showNew"
      class="product-tile-badge product-tile-badge--new text--bold"
      style="
        --badge-xs-size: 1.4em;
        --badge-xs-padding: 0.4em;
        --badge-sm-size: 1.6em;
        --badge-sm-padding: 0.4em;
      "
    >
      NEU
    </Badge>
    <Badge
      v-if="showDiscount"
      aria-label="Rabattiert"
      class="product-tile-badge product-tile-badge--discount text--bold"
      style="--badge-xs-size: 2em; --badge-xs-padding: 0.4em; --badge-sm-size: 3em"
    >
      %
    </Badge>
    <Badge
      v-if="showCustom"
      class="product-tile-badge"
      style="--badge-sm-size: 0.8em"
    >
      <slot>
        <span v-html="content" />
      </slot>

      <slot name="footnoteButton">
        <button
          v-if="footnoteContent"
          ref="footnoteButton"
          class="badge__footnote btn"
          type="button"
          @click.prevent="$refs.modal.open()"
        >
          *
        </button>
      </slot>
    </Badge>
    <Modal ref="modal">
      <slot name="footnoteContent">
        <div v-html="footnoteContent" />
      </slot>
    </Modal>
  </div>
</template>

<script>
import { Badge } from '@i22-td-smarthome/component-library';

import Modal from '@/components/modal.vue';

export default {
  name: 'ProductTileBadge',
  components: {
    Badge,
    Modal,
  },
  props: {
    isNew: { type: Boolean },
    isDiscounted: { type: Boolean },
    content: { type: String, default: '' },
    footnoteContent: { type: String, default: '' },
  },
  computed: {
    showNew() {
      return this.isNew;
    },
    showDiscount() {
      return this.isDiscounted && !this.isNew && !this.showCustom;
    },
    showCustom() {
      return !!this.content;
    },
    hasBadge() {
      return this.showNew || this.showDiscount || this.showCustom;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/base';

.product-tile-badge {
  right: 0;
  white-space: normal;
}
</style>
